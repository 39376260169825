body.luna.one789 #main #layout-content-only #content{
  background: #710b0f;
  position: relative;
  &:before, &:after{
    content: "";
    position: absolute;
    bottom: 0;

    width: calc((100vw - 1400px)/2);
    height: calc(100vh - 90px);
    z-index: 100;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    user-select: none;
  }
  &:before{
    left: 0;
    background-image: url("/assets/images/luna/left.png");
    background-position: bottom left;
  }
  &:after{
    right: 0;
    background-image: url("/assets/images/luna/right.png");
    background-position: bottom right;
  }

  @media screen and (max-width: 2100px) {
    &:before, &:after{
      width: calc((100vw - 1300px)/2);
    }
  }
  @media screen and (max-width: 1920px) {
    &:before, &:after{
      width: calc((100vw - 1200px)/2);
    }
  }
  @media screen and (max-width: 1600px) {
    &:before, &:after{
      display: none;
    }
  }

  #home {
    #banner{
      .slider-content{
        position: relative;
        //width: 1200px;
        //margin: 0 auto;
        .layout-column{
          &:before{
            background: linear-gradient(0deg, rgba(113,11,15,1) 0%, rgba(126,13,16,0) 100%);
            content: "";
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            z-index: 2;
            pointer-events: none;
          }
          &:after{
            content: "";
            background: radial-gradient(circle, rgba(113,11,15,0) 0%, rgba(113,11,15,1) 98%, rgba(113,11,15,1) 100%);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            z-index: 2;
            pointer-events: none;
          }
        }
      }
      .notification-wrap .one-container .notification .content .owl-item a p{
        color: #FFF;
      }
    }
    #production{
      background: none;
    }
  }
}
