@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Thasadith:400,700|Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700italic,700,900,900italic&display=swap');
body.one789 {
  font-family: 'Thasadith', sans-serif;
  font-weight: 400;
  color: #143250;
  position: relative;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 26px;

  #main {
    #layout-content-only {
      #content {
        background: #2b2e59;
        font-size: 1.5rem;

        #home{
          height: 100%;
          #header{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 999;
            padding: 12px 0;
            transition: 0.4s;
            &.sticky{
              background: #2b2d5c;
              position: fixed;
              box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
              padding: 8px;
              transition: 0.4s;
            }
            &:hover{
              background: #2b2d5c;
            }
            @media screen and (max-width: $layout-breakpoint-xs){
              .logo{
                img{
                  height: 36px !important;
                }
              }
              padding: 0;
              &.sticky{
                padding: 8px;
              }
            }

            .one-container{
              .logo{
                img{
                  height: 60px;
                }
              }
              .nav{
                color: #FFF;
                font-weight: 600;
                text-transform: uppercase;
                padding-left: 16px;
                span{
                  margin: 0 16px;
                }

              }
            }

          }


          #banner{
            position: relative;
            background: #fff;
            .slider-content{
              width: 100%;
              display: none;

              &.revert{
                display: block;
              }
              .layout-column{
                .owl-item{
                  img{
                    width: 100%;
                  }
                }
              }
            }
            .notification-wrap{
              width: 100%;
              .one-container{
                .notification{
                  padding: 15px 15px ;
                  .content{
                    border-radius: 8px;
                    overflow: hidden;
                    color: #FFF3E0;
                    font-size: 24px;
                    text-align: right;
                    min-height: 80px;
                    padding: 0;
                    vertical-align: center;
                    .owl-item{
                      a{
                        cursor: pointer;
                        text-align: center;

                        img{
                          width: 110px;
                          @media screen and (max-width: 960px) {
                            width: 90px;
                          }
                          @media screen and (max-width: 600px) {
                            width: 60px;
                          }
                        }
                        p{
                          margin: 8px 0;
                          text-align: center;
                          font-size: 16px;
                          color: #1a1a34;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          #production{
            background-image: url("/assets/images/backgrounds/schedule-bg.png");
            background-repeat: no-repeat;
            background-color: #FFF;
            padding: 10px 0 50px;
            background-size: 100%;

            .product{
              display: flex;
              .column{
                max-width: 100%;
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                @media screen and (max-width: $layout-breakpoint-xs){
                  padding: 0;
                }
                .single-product{
                  position: relative;
                  margin-bottom: 30px;
                  border-radius: 8px;
                  overflow: hidden;
                  background-color: rgb(248, 248, 248);
                  border: 1px solid #ddd;

                  @media screen and (max-width: $layout-breakpoint-xs){
                    margin-bottom: 15px;
                  }

                  .link-wrap{
                    min-height: 100px;

                    .link-agent, .link-member{
                      h4{
                        text-align: center;
                        font-size: 1.5rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #17171c;
                        text-transform: uppercase;
                        font-weight: 700;
                      }

                      ul{
                        margin: 0;
                        padding: 0 24px 16px;

                        li{
                          position: relative;
                          list-style: none;
                          text-transform: uppercase;
                          font-size: 11px;
                          line-height: 32px;
                          font-weight: 600;
                          letter-spacing: 1px;
                          font-family: 'Roboto', sans-serif;
                          &:not(:last-child){
                            border-bottom: 1px solid #ddd;
                          }
                          a{
                            display: block;
                            color: #0014ff;
                            &:hover{
                              color: rgb(228, 91, 45);
                            }
                          }
                          .new{
                            position: absolute;
                            right: 2px;
                            top: 8px;
                            display: initial;
                            font-size: 8px;
                            height: 15px;
                            line-height: 15px;
                            background: #ff0e0094;
                            color: #fff;
                            padding: 0 4px;
                            border-radius: 50%;
                            animation: blinker 2s linear infinite;
                            @media screen and (max-width: $layout-breakpoint-xs){
                              display: none;
                            }
                          }
                        }
                        @media screen and (max-width: 600px) {
                          padding: 0 8px 16px;
                          li{
                            font-size: 10px;
                            a{

                            }
                          }
                        }
                      }

                    }
                  }


                  .product-cont-area{
                    .product-cont{
                      background: linear-gradient( 140deg, rgb(111,0,247) 0%, rgb(25,18,72) 100%);
                      width: 100%;
                      margin: 0;
                      padding: 16px;
                      h3{
                        margin: 0;
                        color: #fff;
                        font-size: 24px;
                        font-weight: 700;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-family: inherit;
                      }
                    }
                    .product-single-logo{
                      z-index: 9;
                      position: absolute;
                      right: 15px;
                      top: 28px;
                      a{
                        background: #303265;
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        box-shadow: 0 5px 10px 0 rgba(20, 50, 80, 0.3);
                        text-align: center;
                        border-radius: 50%;
                        font-size: 16px;
                        font-weight: 600;
                        transition: all 0.5s;
                        img{
                          height: 32px;
                          vertical-align: middle;
                          border-style: none;
                          display: inherit;
                        }
                      }
                    }
                  }
                }

              }

              .accounting, .bot{
                margin: 0 15px;
                border: 1px solid #ddd;
                border-radius: 8px;
                overflow: hidden;
                img{
                  width: 100%;
                }

                @media screen and (max-width: 600px){
                  margin: 0;
                }
              }
              .bot{
                border: none;
                margin-bottom: 15px;
                background-image: url("/assets/images/backgrounds/telegram.jpg");
                //background-repeat: no-repeat;
                background-size: 100%;
                .qr{
                  text-align: center;
                  padding: 16px;
                  img{
                    width: 220px;
                  }
                }
                .context{
                  padding: 15px;
                  font-size: 20px;
                  color: #FFF;
                  font-weight: 500;
                  text-align: center;
                  img{
                    width: 48px;
                    display: inline;
                    margin: 16px 0;
                  }
                }
              }
            }
          }

          #footer{
            color: #e3e6fc;
            .copyright-text{
              width: 100%;

              p{
                color: #e3e6fc;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                margin: 0 0 8px 0;
                @media screen and (max-width: $layout-breakpoint-xs){
                  text-align: center;
                }
              }
            }

            .down-btn{
              margin: 16px 0 0;
              a{
                display: inline-block;
                background: linear-gradient( 0deg, rgb(228, 91, 45) 0%, rgb(240, 121, 33) 33%, rgb(252, 151, 21) 100%);
                border-radius: 10px;
                padding: 10px 10px 10px 30px;
                color: #fff;
                text-transform: uppercase;
                margin-right: 20px;
                box-shadow: 0 10px 10px 0 rgba(20, 50, 80, 0.2);
                font-weight: 700;
                text-decoration: none;
                outline: none;
                transition: 0.3s ease;
                .icon{
                  float: left;
                  width: 30%;
                  margin: 10px 0 0 0;
                  padding-right: 30px;
                  md-icon{
                    color: #FFF;
                  }
                }
                .cont{
                  float: left;
                  width: 70%;
                  line-height: 1.5;
                  small{
                    font-size: 10px;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 600;
                  }
                }
                &.apple{
                  background: linear-gradient( 0deg, rgb(1, 132, 255) 1%, rgb(1, 171, 255) 100%);
                  margin-right: 0;
                }
              }
            }
          }

        }
      }
    }
  }
}


@media screen and (min-width: $layout-breakpoint-md) {
  .one-container{
    max-width: 1280px;
  }
}
.one-container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@keyframes blinker {
  50% { opacity: 0; }
}